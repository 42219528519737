import React from "react";
// import feta5 from "../pageimages/feta/feta5.jpg"
import feta5 from "../pageimages/kasza.jpg";

import { Link } from "react-router-dom";
export default function intro() {
  return (
    <header
      className="intro"
      data-aos="fade-left"
      rel="preload"
      data-aos-duration="1000"
    >
      <article className="top-text-holder">
        <article
          style={{
            position: "relative",
          }}
        >
          <h1 className="introHeader">
            Pyszne domowe jedzenie... <br /> i nie tylko!
          </h1>
        </article>
        <p>
          Easybar oferuje coś dla każdego. Znajdziesz u nas smaczne domowe
          obiady, jedyne w swoim rodzaju schabburgery oraz pyszne pierogi
          ręcznie lepione przez naszych kucharzy. Wszystko w przystępnych
          cenach.
        </p>
        <p>
          Wszystkie dania przygotowywane są według naszych przepisów, z
          regionalnych produktów od zaufanych dostawców.{" "}
        </p>
        <p>
          Nasze posiłki są zawsze świeże, dlatego codziennie znajdziesz u nas
          coś nowego!
        </p>
      </article>
      <article
        className="feta-holder"
        onClick="document.documentElement.scrollIntoView({ behavior: 'smooth' })"
      >
        <h1>Feta 2023</h1>
        <p>
          Kolejny raz mieliśmy możliwośc zapewnienia wyżywienia dla aktorów i
          organizatorów Festawalu Teatrów Ulicznych FETA
        </p>
        <img src={feta5} alt="feta main"></img>
        <Link to="/feta">Czytaj dalej ➨ </Link>
      </article>
    </header>
  );
}
