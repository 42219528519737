import React, { Component } from "react";
import feta1 from "../../pageimages/feta/feta1.jpg";
import feta2 from "../../pageimages/feta/feta2.jpg";
import feta3 from "../../pageimages/feta/feta3.jpg";
import feta4 from "../../pageimages/feta/feta4.jpg";
import feta5 from "../../pageimages/feta/feta5.jpg";
import feta6 from "../../pageimages/feta/feta6.jpg";
import feta8 from "../../pageimages/feta/feta8.jpg";

export default class Carousel extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginBottom: "30px",
        }}
      >
        <div style={{ width: "80%" }}>
          <div class="container-grid">
           
          <div class="gallery-container w-4 h-3">
              <div class="gallery-item">
                <div class="image">
                  <img src={feta5} alt="food" />
                </div>
              </div>
            </div>
            <div class="gallery-container w-2 h-3">
              <div class="gallery-item">
                <div class="image">
                  <img src={feta3} alt="sport" />
                </div>
              </div>
            </div>

            <div class="gallery-container w-2 h-2">
              <div class="gallery-item">
                <div class="image">
                  <img src={feta4} alt="fitness" />
                </div>
              </div>
            </div>
            <div class="gallery-container w-4 h-2">
              <div class="gallery-item">
                <div class="image">
                  <img src={feta2} alt="nature" />
                </div>
              </div>
            </div>
       
            <div class="gallery-container w-2 h-3">
              <div class="gallery-item">
                <div class="image">
                  <img src={feta1} alt="nature" />
                </div>
              </div>
            </div>
            <div class="gallery-container w-2 h-3">
              <div class="gallery-item">
                <div class="image">
                  <img src={feta8} alt="cars" />
                </div>
              </div>
            </div>
          
            <div class="gallery-container w-2 h-3">
              <div class="gallery-item">
                <div class="image">
                  <img src={feta6} alt="travel" />
                </div>
              </div>
            </div>

    

         
          </div>
        </div>
      </div>
    );
  }
}
