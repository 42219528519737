import React from "react";
import fetabg from "../../pageimages/feta/fetabg.jpg";
import fetabg2 from "../../pageimages/feta/fetabg2.jpg";
import logo from "../../pageimages/easylogo.png";
import { Parallax } from "react-parallax";
import Carousel from "./Carousel";

export default function Feta() {
  return (
    <>
      <header className="stypy-container">
        <img src={fetabg} alt="feta" className="cakes-main-image"></img>
        <div className="event-border">
          <h1 className="event-text">FETA 2023</h1>
        </div>
      </header>
      <article className="feta-about">
        <section className="feta-head-text">
          <h1> Feta 2023</h1>
          <span>
            Kolejny raz mieliśmy możliwość zapewnienia wyżywienia w Festawalu
            Teatrów Ulicznych FETA. Wyżywienie obejmowało trzy ciepłe posiłki
            dziennie w formie bufetu szwedzkiego, dla ponad 150 aktorów oraz
            organizatorów, oraz całodostępną kawę i inne napoje. Jedzenie było
            starannie dopasowane do potrzeb uczestników, a wśród naszych obiadów
            pojawiły się oczywiście warianty wegańskie, bezglutenowe i
            bezlaktozowe.
          </span>
        </section>
        <section></section>
      </article>
      <Parallax
        bgImage={fetabg2}
     
        strength={400}
      >
        <div className="feta-parallax"></div>
      </Parallax>

      <Carousel/>
      <div style={{ backgroundColor: "#F8F8F8" }}>
        <div
          id="contact"
          style={{
            width: "100%",
            height: "35%",
            minHeight: "160px",
            display: "flex",
            flexFlow: "column",
          }}
        >
          <div
            className="contact-container"
            style={{
              height: "160",
              display: "flex",
              flexFlow: "row",
              flexWrap: "wrap",
              margin: "5%, 12.5%, 1%, 12.5%",
              alignItems: "center",
              justifyContent: "center",
              color: "#15161c",
            }}
          >
            <aside className="komunie-open">
              <h1
                style={{
                  marginTop: "5%",
                }}
              >
                Godziny Otwarcia
              </h1>
              <p>poniedziałek - piątek</p>
              <p>11:00-18:00</p>
              <p>sobota</p>
              <p>11:00-17:00</p>
            </aside>
            <aside className="komunie-address">
              <h1>Adres</h1>
              <p>ul. Warneńska 8c/2</p>
              <p>Gdańsk, Morena 80-288</p>
            </aside>
            <aside className="komunie-contact">
              <h1>Telefon</h1>
              <p>575-535-645</p>
              <p>530-841-941</p>
            </aside>
          </div>
          <div
            style={{
              backgroundColor: "#15161c",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={logo}
              alt="easybar logo"
              style={{
                width: "6%",
                minWidth: "100px",
              }}
            ></img>
          </div>
        </div>
      </div>
    </>
  );
}
