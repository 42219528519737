import React from "react";
import rekomendacja from "../../pageimages/feta/rekomendacja.jpg"

export default function ContactPage() {
  return (
    <>
      <section className="contact-page-container">
        <article className="info-container">
          <aside className="info-box">
            <h1 className="info-box-header">Skontaktuj się z nami!</h1>
            <aside className="info-contact">
              <button className="info-button">
                <a href="tel:575-535-645">tel: 575 535 645</a>
              </button>
              <button className="info-button">
                <a href="tel:530-841-941">tel: 530 841 941</a>
              </button>
              <button className="info-button">
                <a href="mailto:easybar2011@wp.pl">easybar2011@wp.pl</a>
              </button>
              <button className="info-button">
                <a href="https://www.facebook.com/easybarmorena">
                  Facebook.com/easybarmorena
                </a>
              </button>
            </aside>
            <aside className="info-hours">
              <h1>Godziny Otwarcia</h1>
              <p>poniedziałek - piątek</p>
              <p>11:00-18:30</p>
              <p>sobota - niedziela</p>
              <p>11:00-17:30</p>
            </aside>
            <aside className="info-address">
              <h1>Adres</h1>
              <p>ul. Warneńska 8c/2</p>
              <p>Gdańsk, Morena 80-288</p>
            </aside>
          </aside>
          <div className="map-container">
            <iframe
            title="map"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              id="gmap_canvas"
              src="https://maps.google.com/maps?width=650&amp;height=500&amp;hl=en&amp;q=Warne%C5%84ska%208c/2%20Gda%C5%84sk+(EASYBAR%20-%20Pyszne,%20domowe%20obiady!)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              width="650"
              height="500"
              frameborder="0"
            ></iframe>
            <script
              type="text/javascript"
              src="https://embedmaps.com/google-maps-authorization/script.js?id=af639b203f1b76ca1c1a5d3e817116768cfe24e0"
            ></script>
          </div>
        </article>
      </section>
      <article className="rekomendacja">
        <h3>Rekomendacja od Instytutu Adama Mickiewicza.</h3>
        <p>Dziękujemy!</p>
        <img src={rekomendacja} alt="rekomendacja"></img>
      </article>
    </>
  );
}
