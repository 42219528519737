import React from "react";
import tort1 from "../../pageimages/tort1.jpg";
import tort2 from "../../pageimages/tort2.jpg";
import tort3 from "../../pageimages/tort3.jpg";
import tort4 from "../../pageimages/tort4.jpg";
import tort5 from "../../pageimages/tort5.jpg";

import Slider from "react-slick";
import logo from "../../pageimages/easylogo.png";
import { Parallax } from "react-parallax";

export default function Torty() {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: false,
    arrows: true,
    autoplayspeed: 500,
    infinite: true,
    fade: true,
    width: "655px",
    height: "655px",
  };
  return (
    <>
      <header className="stypy-container">
        <img src={tort4} alt="komunie" className="cakes-main-image" ></img>
        <div className="event-border">
          <h1 className="event-text">TORTY DOMOWEJ PRODUKCJI</h1>
        </div>
      </header>
      <article className="torty-about">
        <section className="torty-head-text">
          <h1>Ciasta i torty na każdą okazję</h1>
          <span>
            Produkcja szerokiej gamy ciast i tortów opiera się na tradycyjnych
            recepturach i domowym sposobie ich wykonania. Wszystkie nasze
            wypieki sa dopasowane dokładnie do Państwa potrzeb i oczekiwań.
            Naszym szczególnym atutem są torty okolicznościowe "dobre, takie nie
            za słodkie".
          </span>
          <button className="torty-button">
            <a
              href="tel:786-266-076"
            >
              Zadzwoń i zamów
            </a>
          </button>
        </section>
        <section>
          <Slider
            {...settings}
            style={{
              width: "500px",
            }}
          >
            <img
              src={tort1}
              alt="tort"
              style={{
                height: "655px",
                width: "655px",
              }}
            />
            <img
              src={tort2}
              alt="tort"
              style={{
                height: "655px",
                width: "655px",
              }}
            />
            <img
              src={tort3}
              alt="tort"
              style={{
                height: "655px",
                width: "655px",
              }}
            />
          </Slider>
        </section>
      </article>
      <Parallax bgImage={tort5} blur={{ min: -15, max: 15 }} strength={300}>
        <article className="torty-parallax">
              <h1>Zamówienia</h1>
              <span>Wszystkie nasze produkty możesz zamówić i odebrać w naszym barze. Oferujemy również dowóz na wybrany adres.</span>
              <span>Zamówienia przyjmujemy pod telefonem 786 266 076, można także do nas napisać na adres mailowy easybar2011@wp.pl lub odwiedzić nasz bar.</span>
        </article>
      </Parallax>
      <div style={{ backgroundColor: "#F8F8F8" }}>
          <div
            id="contact"
            style={{
              width: "100%",
              height: "35%",
              minHeight: "160px",
              display: "flex",
              flexFlow: "column",
            }}
          >
            <div
              className="contact-container"
              style={{
                height: "160",
                display: "flex",
                flexFlow: "row",
                flexWrap: "wrap",
                margin: "5%, 12.5%, 1%, 12.5%",
                alignItems: "center",
                justifyContent: "center",
                color: "#15161c",
              }}
            >
              <aside className="komunie-open">
                <h1
                  style={{
                    marginTop: "5%",
                  }}
                >
                  Godziny Otwarcia
                </h1>
                <p>poniedziałek - piątek</p>
                <p>11:00-18:00</p>
                <p>sobota</p>
                <p>11:00-17:00</p>
              </aside>
              <aside className="komunie-address">
                <h1>Adres</h1>
                <p>ul. Warneńska 8c/2</p>
                <p>Gdańsk, Morena 80-288</p>
              </aside>
              <aside className="komunie-contact">
                <h1>Telefon</h1>
                <p>575-535-645</p>
                <p>530-841-941</p>
              </aside>
            </div>
            <div
              style={{
                backgroundColor: "#15161c",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={logo}
                alt="easybar logo"
                style={{
                  width: "6%",
                  minWidth: "100px",
                }}
              ></img>
            </div>
          </div>
        </div>
    </>
  );
}
