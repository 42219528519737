import React from "react";
import schab from "../pageimages/schab.png";
import schabburger from "../pageimages/schabburger.png";
import pierogi from "../pageimages/pierogi.png";
import sernik from "../pageimages/sernik.png";
export default function About() {
  return (
    <section id="about" data-aos="fade-right" data-aos-duration="1000">
      <h1>Nasze najlepsze:</h1>
      <article className="container">
        <aside className="box" data-aos="fade-right" data-aos-duration="1000">
          <img
            src={schab}
            alt="schabowy"
            style={{
              width: "100%",
              minWidth: "400px",
            }}
          ></img>
          <b>
            <p>Schabowy XXL z karkówką</p>
          </b>
        </aside>
        <aside class="box" data-aos="fade-left" data-aos-duration="1000">
          {" "}
          <img
            src={schabburger}
            alt="schabburger"
            style={{
              width: "100%",
              minWidth: "400px",
            }}
          ></img>
          <b>
            {" "}
            <p>Schabburger klasyczny</p>
          </b>
        </aside>
        <aside class="box" data-aos="fade-right" data-aos-duration="1000">
          {" "}
          <img
            src={pierogi}
            alt="pierogi"
            style={{
              width: "100%",
              minWidth: "400px",
            }}
          ></img>
          <b>
            {" "}
            <p>Pierogi z mięsem</p>
          </b>
        </aside>
        <aside class="box" data-aos="fade-left" data-aos-duration="1000">
          {" "}
          <img
            src={sernik}
            alt="sernik"
            style={{
              width: "100%",
              minWidth: "400px",
            }}
          ></img>
          <b>
            {" "}
            <p>Sernik Wiedeński</p>
          </b>
        </aside>
      </article>
    </section>
  );
}
