import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {Routes, Route, BrowserRouter } from "react-router-dom";
import Main from "./components/Main"
import Stypy  from "./components/stypy/Stypy";
import Komunie from "./components/komunie/Komunie"
import Torty from "./components/cakes/Torty"
import CateringPage from "./components/CateringPage"
import Event from "./components/Event"
import Navbar from "./components/nav/Navbar";
import ContactPage from "./components/kontakt/ContactPage";
import MessengerCustomerChat from 'react-messenger-customer-chat';
import Feta from "./components/feta/Feta";
import ScrollToTop from "./utils/ScrollToTop"

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <ScrollToTop/>

    <Navbar />
    <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/catering" element={<CateringPage />} />
        <Route path="/stypy" element={<Stypy />} />
        <Route path="/komunie" element={<Komunie />} />
        <Route path="/torty" element={<Torty />} />
        <Route path="/event" element={<Event />} />
        <Route path="/feta" element={<Feta />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </BrowserRouter>
    <MessengerCustomerChat
    pageId="384151384987413"
    appId="255060649938522"  />
  </React.StrictMode>,
  document.getElementById("root")
);
